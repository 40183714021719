export const AuthError = {
    // Лучше не выводить BAD_REQUEST пользователю, а честно валидировать данные
    // в форме и показывать конкретное сообщение об ошибке
    BAD_REQUEST: 'Убедитесь, что вы ввели корректные данные',
    USER_ALREADY_EXISTS: 'Пользователь с таким email уже существует',
    USER_NOT_FOUND: 'Пользователь с таким логином не существует',
    PASSWORD_INCORRECT: 'Неверный пароль',
    UNKNOWN_ERROR: 'Произошла неизвестная ошибка',
    UNSUCCESSFUL_LOGOUT: 'Не удалось выйти из аккаунта',
} as const;
