import { objectKeys } from '@/utils';

export const APP_NODE_EVENT_MAP = {
    onAbort: 'abort',
    onAnimationCancel: 'animationcancel',
    onAnimationEnd: 'animationend',
    onAnimationIteration: 'animationiteration',
    onAnimationStart: 'animationstart',
    onAuxClick: 'auxclick',
    onBeforeInput: 'beforeinput',
    onBeforeToggle: 'beforetoggle',
    onBlur: 'blur',
    onCancel: 'cancel',
    onCanplay: 'canplay',
    onCanplaythrough: 'canplaythrough',
    onChange: 'change',
    onClick: 'click',
    onClose: 'close',
    onCompositionEnd: 'compositionend',
    onCompositionStart: 'compositionstart',
    onCompositionUpdate: 'compositionupdate',
    onContextmenu: 'contextmenu',
    onCopy: 'copy',
    onCuechange: 'cuechange',
    onCut: 'cut',
    onDblclick: 'dblclick',
    onDrag: 'drag',
    onDragend: 'dragend',
    onDragenter: 'dragenter',
    onDragleave: 'dragleave',
    onDragover: 'dragover',
    onDragstart: 'dragstart',
    onDrop: 'drop',
    onDurationchange: 'durationchange',
    onEmptied: 'emptied',
    onEnded: 'ended',
    onError: 'error',
    onFocus: 'focus',
    onFocusin: 'focusin',
    onFocusout: 'focusout',
    onFormData: 'formdata',
    onGotpointercapture: 'gotpointercapture',
    onInput: 'input',
    onInvalid: 'invalid',
    onKeyDown: 'keydown',
    onKeyPress: 'keypress',
    onKeyUp: 'keyup',
    onLoad: 'load',
    onLoadedData: 'loadeddata',
    onLoadedMetadata: 'loadedmetadata',
    onLoadStart: 'loadstart',
    onLostPointerCapture: 'lostpointercapture',
    onMouseDown: 'mousedown',
    onMouseEnter: 'mouseenter',
    onMouseLeave: 'mouseleave',
    onMouseMove: 'mousemove',
    onMouseOut: 'mouseout',
    onMouseOver: 'mouseover',
    onMouseUp: 'mouseup',
    onPaste: 'paste',
    onPause: 'pause',
    onPlay: 'play',
    onPlaying: 'playing',
    onPointerCancel: 'pointercancel',
    onPointerDown: 'pointerdown',
    onPointerEnter: 'pointerenter',
    onPointerLeave: 'pointerleave',
    onPointerMove: 'pointermove',
    onPointerOut: 'pointerout',
    onPointerOver: 'pointerover',
    onPointerUp: 'pointerup',
    onProgress: 'progress',
    onRateChange: 'ratechange',
    onReset: 'reset',
    onResize: 'resize',
    onScroll: 'scroll',
    onScrollEnd: 'scrollend',
    onSecurityPolicyViolation: 'securitypolicyviolation',
    onSeeked: 'seeked',
    onSeeking: 'seeking',
    onSelect: 'select',
    onSelectionChange: 'selectionchange',
    onSelectStart: 'selectstart',
    onSlotChange: 'slotchange',
    onStalled: 'stalled',
    onSubmit: 'submit',
    onSuspend: 'suspend',
    onTimeupdate: 'timeupdate',
    onToggle: 'toggle',
    onTouchCancel: 'touchcancel',
    onTouchEnd: 'touchend',
    onTouchMove: 'touchmove',
    onTouchstart: 'touchstart',
    onTransitionCancel: 'transitioncancel',
    onTransitionEnd: 'transitionend',
    onTransitionRun: 'transitionrun',
    onTransitionStart: 'transitionstart',
    onVolumeChange: 'volumechange',
    onWaiting: 'waiting',
    onWebkitAnimationEnd: 'webkitanimationend',
    onWebkitAnimationIteration: 'webkitanimationiteration',
    onWebkitAnimationStart: 'webkitanimationstart',
    onWebkitTransitionEnd: 'webkittransitionend',
    onWheel: 'wheel',
} as const;

/** Тип для ключей ивентов  */
export type AppNodeEventType = keyof typeof APP_NODE_EVENT_MAP;

export const APP_NODE_EVENTS = objectKeys(APP_NODE_EVENT_MAP);
